"use client"
import { AnimatePresence, motion } from "framer-motion";
import { usePathname } from "next/navigation";
import { useLayoutEffect, useRef } from "react";

export const LayoutTransition = ({ children }) => {
  const pathname = usePathname();
  const currentPageRef = useRef(null);

  // useLayoutEffect(() => {
  //   if (!currentPageRef.current) return;

  // }, [pathname]);


  return (

    <motion.div
      key={pathname}
      initial={{ y: 0, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ ease: "easeInOut", duration: 0.75 }}
    >
      <div ref={currentPageRef}>{children}</div>
    </motion.div>

  );
};