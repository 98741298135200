'use client';

import { Button, HR, MegaMenu, Navbar } from 'flowbite-react';
import Link from 'next/link';
import { HiArrowRight } from 'react-icons/hi';
import { motion } from "framer-motion"
import { useState } from 'react';
import Image from 'next/image';
import { IconCalendarCheck, IconUser } from '@tabler/icons-react';
const Nav = () => {
    const [industries, setIndustries] = useState(false)
    return (
        <MegaMenu className="fixed w-full z-[1000000] top-0 shadow-lg">
            <Navbar.Brand href="/">
                <Image alt="" src="/logo-dark.png" width={200} height={60} className="mr-3 w-44 lg:w-56" />
            </Navbar.Brand>
            <div className="order-2 lg:mr-5 mr-1 hidden items-center md:flex ml-auto md:mt-4 lg:mt-0">

                <Button as={Link} className='bg-black hover:opacity-70  rounded-full text-md py-2 px-6 md:text-xl mr-4' href="/contact-us#booking-demo">Book a Demo <IconCalendarCheck className='ml-2' /></Button>
                <Button className=' bg-black hover:opacity-70  rounded-full text-md py-2 px-6 md:text-xl' href="https://app.omniwink.com.au">Login <IconUser className='ml-2' /></Button>
            </div>
            <Navbar.Toggle className='ms-auto' />
            <Navbar.Collapse className='mx-auto'>
                <Link href="/">
                    <Navbar.Link as="div">
                        Home
                    </Navbar.Link>
                </Link>
                <Link href="/about-us">
                    <Navbar.Link as="div">
                        About Us
                    </Navbar.Link>
                </Link>
                <div className='block py-2 pl-3 pr-4 md:p-0 border-b border-gray-100 text-gray-700 hover:bg-gray-50 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white md:border-0 md:hover:bg-transparent md:hover:text-cyan-700 md:dark:hover:bg-transparent md:dark:hover:text-white'>
                    <MegaMenu.Dropdown className="mx-auto text-black" toggle={<div>Industries</div>} >
                        <div className="border-y max-w-screen-lg  border-gray-200 bg-white shadow-sm dark:border-gray-600 dark:bg-gray-800">
                            <h4 className="pl-5 pt-3 text-black dark:text-black font-bold">Industries</h4>
                            <HR className='p-0 m-0 ml-5 bg-black mt-2 w-1/4' />
                            <div className="mx-auto grid max-w-screen-xl px-4 py-5 text-sm text-gray-500 dark:text-gray-400 grid-cols-2 md:grid-cols-3 md:px-6">
                                <ul className="mb-4 space-y-4 md:mb-0 " aria-labelledby="mega-menu-full-image-button">
                                    <li>
                                        <Link className="transition ease-in-out delay-150 hover:border-b-2  hover:text-black border-neutral-600 hover:animate-pulse duration-300" href='/industries/car-detailing'>Car Detailing Industry</Link>
                                    </li>
                                    <li>
                                        <Link href='/industries/cleaning' className="transition ease-in-out delay-150 hover:border-b-2  hover:text-black border-neutral-600 hover:animate-pulse duration-300 ">
                                            Cleaning Industry
                                        </Link>
                                    </li>
                                    <li>
                                        <Link href='/industries/solar' className="transition ease-in-out delay-150 hover:border-b-2  hover:text-black border-neutral-600 hover:animate-pulse duration-300 ">
                                            Solar Industry
                                        </Link>
                                    </li>
                                </ul>
                                <ul className="mb-4 space-y-4 md:mb-0">
                                    <li>
                                        <Link href='/industries/real-estate' className="transition ease-in-out delay-150 hover:border-b-2  hover:text-black border-neutral-600 hover:animate-pulse duration-300 ">
                                            Real Estate
                                        </Link>
                                    </li>
                                    <li>
                                        <Link href='/industries/gym-and-chiropracter' className="transition ease-in-out delay-150 hover:border-b-2  hover:text-black border-neutral-600 hover:animate-pulse duration-300 ">
                                            Gym and Chiropracter
                                        </Link>
                                    </li>
                                    <li>
                                        <Link href='/industries/coaching-and-education' className="transition ease-in-out delay-150 hover:border-b-2  hover:text-black border-neutral-600 hover:animate-pulse duration-300 ">
                                            Coaching and Education
                                        </Link>
                                    </li>

                                </ul>
                                <a>
                                    <Image src={'/leadership.png'} width={250} height={100} className='rounded-lg mx-auto lg:w-52' alt="Business Growth - Omniwink" />
                                </a>
                            </div>
                        </div>
                    </MegaMenu.Dropdown>
                </div>

                <div className='block py-2 pl-3 pr-4 md:p-0 border-b border-gray-100 text-gray-700 hover:bg-gray-50 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white md:border-0 md:hover:bg-transparent md:hover:text-cyan-700 md:dark:hover:bg-transparent md:dark:hover:text-white'>
                    <MegaMenu.Dropdown className="mx-auto text-black" toggle={<>Services</>}>
                        <div className="border-y max-w-screen-sm  border-gray-200 bg-white shadow-sm dark:border-gray-600 dark:bg-gray-800">
                            <h4 className="pl-5 pt-3 text-black dark:text-black font-bold">Services</h4>
                            <HR className='p-0 m-0 ml-5 bg-black mt-2 w-1/4' />
                            <div className="mx-auto grid max-w-screen-sm px-4 py-5 text-sm text-gray-500 dark:text-gray-400 grid-cols-2 md:grid-cols-2 md:px-6">
                                <ul className="mb-4 space-y-4 md:mb-0 " aria-labelledby="mega-menu-full-image-button">
                                    <li>
                                        <Link className="hover:text-primary-600 " href='/services/ai-automation'>AI Automation</Link>
                                    </li>
                                    <li>
                                        <Link href='/services/ai-voice-agent' className="hover:text-primary-600 ">
                                            AI Voice Agent
                                        </Link>
                                    </li>
                                    <li>
                                        <Link href='/services/ai-employee' className="hover:text-primary-600 ">
                                            AI Employee
                                        </Link>
                                    </li>

                                </ul>
                                {/* <ul className="mb-4 space-y-4 md:mb-0">
                                    <li>
                                        <a target='_blank' href='https://review-management.omniwink.com.au/' className="hover:text-primary-600 ">
                                            AI Reputation Management
                                        </a>
                                    </li>
                                    <li>
                                        <a target='_blank' href='https://website-development.omniwink.com.au/' className="hover:text-primary-600 ">
                                            Website Development
                                        </a>
                                    </li>
                                    <li>
                                        <a target='_blank' href='https://webchat.omniwink.com.au/' className="hover:text-primary-600 ">
                                            Webchat AI
                                        </a>
                                    </li>

                                </ul> */}
                                <a>
                                    <motion.div
                                        initial={{
                                            opacity: 0.7
                                        }}
                                        animate={{

                                            opacity: 1,
                                        }}
                                        transition={{
                                            duration: 1,
                                            ease: "easeOut", // Smooth easing
                                            repeat: Infinity, // Loop infinitely
                                            repeatType: "loop", // Continuous loop
                                        }}
                                    >
                                        <Image src={'/robot.png'} width={150} height={100} className='rounded-lg lg:h-32' alt="Business Growth - Omniwink" />
                                    </motion.div>
                                </a>
                            </div>
                        </div>
                    </MegaMenu.Dropdown>
                </div>
                <Link href="/pricing">
                    <Navbar.Link as="div">
                        Pricing
                    </Navbar.Link>
                </Link>
                <Link href="/contact-us">
                    <Navbar.Link as="div">
                        Contact Us
                    </Navbar.Link>
                </Link>

            </Navbar.Collapse>


        </MegaMenu>
    )
}

export default Nav